$(function() {
    var eminent = {
        // General elements
        generalElements: function() {
            // Set default config for jquery validate
            $.validator.setDefaults({
                debug: false,
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorElement: 'div',
                errorPlacement: function(error, element) {
                    error.addClass('invalid-feedback');
                    if ( element.prop( "type" ) === "checkbox" ) {
                        error.insertAfter( element.next( "label" ) );
                    } else {
                        error.insertAfter( element );
                    }
                    //error.insertAfter( element );
                }
            });

            /**
             * Setup CSRF token in ajax request
             */
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            /**
             * This is for delete buttons that are loaded via AJAX in datatables, they will not work right
             * without this block of code
             */
            $(document).ajaxComplete(function(){

            });

            // Slider out home page overlay
            if($('#slideout_inner').length) {
                setTimeout(function () {
                    document.getElementById('slideout_inner').setAttribute('id', 'slideout_inner2');
                }, 3000);
            }
        },
        // Validate general form submission
        validateFormSubmission: function () {
            $('.validate-form').validate({ // initialize the plugin
                rules: {},
                messages: {},
                submitHandler: function(form) {
                    $(form)
                        .find('button[type="submit"]')
                        .addClass('disabled')
                        .attr('disable', true)
                        .html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Checking...');
                    form.submit();
                }
            });
        },

        // Disable button
        disableButton: function (form) {
            $(form)
                .find('button[type="submit"]')
                .addClass('disabled')
                .attr('disable', true)
                .html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Processing...');
        },

        // Enable button
        enableButton: function (form, btnHtml) {
            $(form)
                .find('button[type="submit"]')
                .removeClass('disabled')
                .attr('disable', false)
                .html(btnHtml);
        },
        // Scroll to div
        scrollToDiv: function (element) {
            $('html, body').animate({
                scrollTop: $("#" + element).offset().top
            }, 2000);
        },
        // Home page
        homePage: function () {
            if($('.blog-slider').length) {
                var swiper = new Swiper('.blog-slider', {
                    spaceBetween: 30,
                    effect: 'fade',
                    loop: true,
                    mousewheel: {
                        invert: true,
                    },
                    // autoHeight: true,
                    pagination: {
                        el: '.blog-slider__pagination',
                        clickable: true,
                    }
                });
            }
        },
        // About us page
        aboutUs: function () {
            // Hero typed
            if ($('.typed').length) {
                var typed_strings = $(".typed").data('typed-items');
                typed_strings = typed_strings.split(',')
                new Typed('.typed', {
                    strings: typed_strings,
                    loop: true,
                    typeSpeed: 30,
                    backSpeed: 20,
                    backDelay: 2000
                });
            }
        },
        // Get TC detail
        searchStudentTc: function () {
            $('#download-tc').validate({ // initialize the plugin
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorElement: 'label',
                rules: {
                    scholar_number: {
                        required: true
                    }
                },
                messages: {},
                submitHandler: function(form) {
                    // Hide the error message
                    $('#error-message').addClass('d-none');

                    // Show processing
                    let $btn = $(form).find('button[type="submit"]');
                    $btn.html('Processing...').prop('disabled', true);

                    let action = $(form).attr('action');
                    let formData = $(form).serialize();

                    axios.post(action, formData)
                        .then(function(response) {
                            if(response.data.status == 'error') {
                                $('#tc-detail').addClass('d-none')
                                $('#error-message').html(response.data.message).removeClass('d-none');
                            }
                            else if(response.data.status == 'success') {
                                $('#tc-form-container').addClass('d-none')
                                $('#tc-detail').removeClass('d-none')
                                $('table#tc-table > tbody').html(response.data.tc_data);
                            }
                        })
                        .catch(function(error) {
                            $('#tc-detail').addClass('d-none')
                            $('#error-message').html(error.response.data.message).removeClass('d-none');
                        })
                        .finally(function () {
                            $btn.html('View TC').prop('disabled', false);
                        });
                    return false;
                }
            });

            // Download other TC
            $('body').on('click', '#download-other-tc', function () {
                $('#tc-form-container').removeClass('d-none')
                $('#tc-detail').addClass('d-none')
                $('#scholar_number').val('');
            });
        },
        submitContactUsQuery: function () {
            $('#contact-form').validate({ // initialize the plugin
                errorClass: 'is-invalid',
                validClass: 'is-valid',
                errorElement: 'label',
                rules: {
                    scholar_number: {
                        required: true
                    }
                },
                messages: {},
                submitHandler: function(form) {
                    // Show processing
                    let $btn = $(form).find('button[type="submit"]');
                    $btn.html('Processing...').prop('disabled', true);

                    // Hide error and success message if visible
                    $('.error-message').hide();
                    $('.sent-message').hide();

                    let action = $(form).attr('action');
                    let formData = $(form).serialize();

                    axios.post(action, formData)
                        .then(function(response) {
                            if(response.data.status == 'error') {
                                $('.error-message').html(response.data.message).show();
                            }
                            else if(response.data.status == 'success') {
                                $('.sent-message').html(response.data.message).show();
                                $(form).trigger("reset");
                            }
                        })
                        .catch(function(error) {
                            $('.error-message').html(error.response.data.message).show();
                        })
                        .finally(function () {
                            $btn.html('Send Query').prop('disabled', false);
                        });
                    return false;
                }
            });
        },
        // Initialize the functions
        init: function () {
            eminent.generalElements();
            eminent.validateFormSubmission();
            eminent.homePage();
            eminent.aboutUs();
            eminent.searchStudentTc();
            eminent.submitContactUsQuery();
        },
    };

    // Initialize the script
    eminent.init();
});
